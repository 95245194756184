.cta {
    background: $cc-blue;
    color: $cc-white;
    padding: 15px 25px;
    border-radius: 100px;
    transition: background-color .2s ease, color .2s ease;
    border: 1px solid $cc-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: unset;
        color: $cc-blue;
    }
}