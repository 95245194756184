.PrivacyPagesPage {
    .Main {
        border-bottom: 1px solid $cc-lightGrey;
    }
}

.Privacy {
    margin-top: 260px;
    max-width: 1640px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;

    > h1 {
        margin-bottom: 80px;
    }
    
    &-content {
        display: flex;
        flex-direction: column;
    }

    &-block + &-block {        
        margin-top: 40px;        
    }
}