/* Main */
html {
    background-color: $bg-color;
    color: $text-color;
    font-family: $primaryFont;
    height: 100%;
    scroll-behavior: smooth;

    &.fonts-loaded {
        font-family: $primaryFont;
    }

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    min-height: 100%;
    position: relative;
    overflow-x: hidden;
}

.hide {
    display: none;
}

.inactive {
    opacity: .4;
    pointer-events: none;
}