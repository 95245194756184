.Contact {
    display: flex;
    width: 100%;

    &-main {
        margin-top: 260px;
        max-width: $container;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start; 
        padding: 0 20px;
        width: 100%;
        
        > h1 {
            margin-bottom: 70px;
        }
        
        .Maps-container { 
            width: 100%;
            margin: 100px 0;            
                   
            .Maps {
                height: 440px;   
            }
        }
    }

    &-info {
        max-width: 500px;
        width: 100%;
        margin-right: 120px;

        &--address, &--contact {
            * {
                line-height: 26px;
            }
        }

        &--address {
            margin-bottom: 50px;

            p {
                margin-bottom: 0;
                display: flex;
                align-items: center;

                img {
                    width: 24px;
                    height: 24px;                    
                }

                strong {
                    margin-right: 5px;
                }
            }

            .map-marker {
                transition: opacity .2s ease;
            }

            &:not(.active-address) {
                .map-marker {
                    opacity: .4;
                }
            }
        }

        &--contact {
            display: flex;
            flex-direction: column;
            width: fit-content;
            padding-bottom: 50px;
            border-bottom: 1px solid $cc-blue;
        }
    }

    form {
        width: 100%;
    }

    @include mq($until: 1500px) {
        &-info {
            margin-right: 160px;
            max-width: unset;
            width: 350px;
        }
    }
  
    @include mq($until: 900px) {
        flex-direction: column;

        &-info {
            margin-right: 40px;
            margin-bottom: 50px;
        }
    }

    @include mq($until: $viewport--md) {
        flex-direction: column;

        &-info {
            margin-right: 0;
            margin-bottom: 30px;

            &--address {
                margin-bottom: 20px;
            }

            &--contact {
                padding-bottom: 30px;
            }
        }

        &-main { 
            margin-top: calc(#{$navHeight} + 80px);
                       
            > h1 {
                margin-bottom: 20px;
            }

            .Maps-container {  
                margin: 30px 0;
            }
        }
    }
}

