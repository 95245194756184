/* Navigation */
.Navigation {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 160px;
    padding-right: 160px;

    @include mq($until: $resp-nav, $media-type: $mq-type, $mq_debug: null) {
        flex-direction: row-reverse;
        padding-right: 20px;
        padding-left: 20px;
    }

    &-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include mq($until: $resp-nav, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column-reverse;
            height: max-content;
        }
    }

    &-button {
        display: none;
    }

    #menu-button {
        position: relative;
        z-index: 5;
        cursor: pointer;
        user-select: none;
        height: 27px;
        // right: 30px;
        // top: calc(50% - 18px);

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;

        @include mq($from: $resp-nav, $media-type: $mq-type, $mq_debug: null) {
            display: none !important;
        } 

        span {
            display: block;
            position: relative;
            height: 2px;
            width: 30px;
            border-radius: 2px;
            opacity: 1;
            background-color: $black;

            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: .3s ease-in-out;

            &:nth-child(1) {
                top: 1px;
            }

            &:nth-child(2) {
                top: 7px;
            }

            &:nth-child(3) {
                top: 13px;
            }
        }
    }


    &-button:checked ~ #menu-button {
        
        span {
            background-color: $white;
            
            &:nth-child(1) {
                top: 9px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                top: 6px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }
        }
    }

    &-button:checked ~ .Navigation-body {
        transform: translateX(0);
        transition: all .2s ease-in-out;
        opacity: 1 !important;
        pointer-events: all;
    }

    &-button:checked ~ .Logo {
        svg {
            path {
                transition: .2s fill ease;
                // fill: $white;
            }
        } 

        @include mq($until: $resp-nav, $media-type: $mq-type, $mq_debug: null) {
            max-width: unset !important;
            width: 80%;
            transform: translateX(0);
            background-color: $cc-black;
        }
    }

    .Logo {
        transition: background-color .2s ease;
    }

    &-body {
        z-index: 2;
        display: flex;

        @include mq($until: $resp-nav, $media-type: $mq-type, $mq_debug: null) {
            opacity: 0;
            position: absolute;
            pointer-events: none;
            width: 100%;
            height: 100vh;
            overflow-y: scroll;
            top: 0;
            left: 0;
            background-color: $cc-black;
            padding-top: 140px;

            

            .Navigation {
                &-container {
                    align-items: flex-start !important;
                }

                &-above {
                    position: relative;
                    justify-content: space-between;
                    width: 100%;
                    background-color: $cc-black;
                }

                &--secondary {
                    background-color: unset !important;

                    svg {
                        path {
                            fill: $cc-white;
                        }
                    }

                    .line {
                        background-color: $cc-white !important;
                    }

                    .Navigation-bottom  {
                        .Nav-link {
                            color: $cc-white;
                        }
                    }
                }

                &--primary {
                    margin-left: 20px !important;

                    ul {
                        li {
                            &:not(.has-dropdown) {
                                margin-bottom: 20px;
                            }

                            a {
                                margin-left: 10px !important;                                
                                width: max-content;
                                color: $cc-white !important;
                            }
                        }
                    }

                    .Nav-dropdown {
                        visibility: visible;
                        opacity: 1;
                        position: relative;
                        top: 0;
                        margin-bottom: 5px;
                        background-color: unset !important;
                        margin-left: 0 !important;
                        padding-top: 5px;

                        a {
                            font-weight: 400;
                            transition: all .2s ease !important;
                            color: $cc-white !important;

                            &:not(:last-of-type) {
                                margin-bottom: 5px;
                            }

                            &:hover {
                                color: $cc-white !important;
                            }
                        }
                    }
                }
            }
        } 

        @include mq($until: $viewport--sm) {
            padding-top: 60px;

            .Navigation {
                &--primary {
                    margin-left: 10px !important;
                }

                &--secondary {
                    padding-right: 10px;
    
                    .socials {
                        padding-left: 20px;
                    }
    
                    .line {
                        margin-right: -5px !important;
                        margin-left: 25px !important;
                    }
    
                    .Nav-link {
                        margin-left: 30px;
                    }
                }
            }
        }
    }

    &-top {
       
        .Nav-item {
            transition: padding .3s ease;
            background-color: $black;
            padding: 15px;
            
            .Nav-link {
                color: $white;
                font-size: 16px;
                font-weight: 400;
                margin-left: 0;
                padding-bottom: 0;

                &::after {
                    display: none;
                }

                &:hover, &:active, &:focus {
                    color: $cc-grey;

                    &::after {
                        display: none;
                    }
                }
                
                &.is-active {
                    color: $black;
                    
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &-bottom {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

/* Types */
@import '6-components/navigation/main';
@import '6-components/navigation/lang';
@import '6-components/navigation/footer';
