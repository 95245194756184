.branch {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 80px 0;

    &:not(:last-of-type) {
        border-bottom: 1px solid $cc-lightGrey;
    }

    > .text-block {
        display: grid;
        grid-template-columns: 500px auto;

        > div {
            &:not(:last-of-type) {
                margin-right: 120px;
            }
        }

        .full-address {
            max-width: 300px;
        }

        div {
            p, span {
                margin-bottom: 0;
                color: $cc-black;
            }            
        }

        .text {
            * {
                font-size: 20px;
                line-height: 26px;
            }

            &:not(:last-of-type) {
                margin-bottom: 30px;
            }
        }

        .contact {
            display: flex;
            flex-direction: column;

            a {
                font-weight: normal;
                color: $cc-black;

                &:hover {
                    color: $cc-blue;
                }
            }
        }

        .names {
            max-width: 430px;
        }

        .contact-us {
            display: flex;
            margin-top: 40px;
            
            a {
                margin-right: 20px;
            }
        }

        margin-right: 50px;
    }

    > figure {
        max-width: 480px;
        max-height: 340px;    
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &es {
        width: 100%;
        margin-bottom: 50px;
    }

    @include mq($until: 1400px) {
        > .text-block {
            grid-template-columns: auto;
        }
    }
   
    @include mq($until: 960px) {
        > figure {
            max-width: 350px;
            max-height: 250px;
        }
    }
    
    @include mq($until: $viewport--md) {
        flex-direction: column;
        padding: 30px 0;

        > .text-block {
            .text {
                * {
                    font-size: 16px;
                    line-height: 20px;
                }

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }

            .contact-us {
                margin-top: 10px;
            }
        }

        > figure {
            margin-top: 40px;
        }
    }
}
