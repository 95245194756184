.Search {
    &-section {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 10000;    
        transition: opacity .2s ease;
        pointer-events: all;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 40px;

        .background-color {
            background-color: rgba(0,0,0, .7);
            width: 100%;
            height: 100%;
            position: fixed;
            z-index: -1;
        }

        &--close {
            position: absolute;
            right: 80px;
            top: 80px;
            cursor: pointer;
        }
    
        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    
        form {
            position: relative;
            max-width: 500px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
    
            input {
                width: 100%;
                background-color: rgba($cc-black, 0);
                color: $cc-white;
                border: 1px solid $cc-white;
                padding: 15px 30px;
                padding-right: 55px;
            }
    
            button {
                position: absolute;
                right: 20px;
                top: 17px;
                pointer-events: none;
                
                &.searching {
                    cursor: pointer;
                    pointer-events: all;
                }
    
                svg {
                    path {
                        fill: $cc-white;
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            &--close {
                top: 40px;
                right: 40px;
            }

            form {
                input {
                    font-size: 16px;
                }
            }
        }
        
        @include mq($until: 400px) {
            padding: 0 20px;

            &--close {
                top: 40px;
                right: 20px;
            }
        }
    }

    &-results {
        position: absolute;
        margin-top: 10px;
        margin-left: 5px;
        max-height: 150px;
        overflow-y: scroll;
        padding-right: 40px;
        width: 94%;

        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
            background: rgba($cc-black, 0); 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $cc-white;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $cc-white; 
        }

        &--item {
            list-style: none;

            a {
                color: rgba($cc-white, .2);
                font-weight: 400;

                &:hover {
                    color: $cc-white;
                }
            }
        }

        @include mq($until: $viewport--sm) {
            max-height: 100px;
        }
    }
}