

.slideshow-container {
    max-width: 960px;

    .single-image, .splide__slide {
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid $cc-grey;
    }

    .single-image {
        height: 480px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include mq($until: $viewport--sm) {
            height: 280px;
        }
    }

    .splide {
        &__arrow {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $cc-white;
            border: 1px solid $cc-grey;
            transition: opacity .2s ease;

            svg {
                height: 18px;
                width: 18px;
            }

            &:disabled {
                opacity: 0;
                pointer-events: none;
            }
        }

        &#primary-slider {            
            .splide {
                &__slide {
                    height: 640px;
                }
            }
        }        

        picture {            
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        img {
            width : 100%;
            height: 100%;
            object-fit: cover;
        }
    
        &#secondary-slider {
            margin-top: 10px;

            .splide {
                &__slide {
                    border-radius: 12px;
                    border-width: 1px;
                    cursor: pointer;
                    transition: border-color .2s ease, opacity .2s ease;
                    overflow: hidden;
                    width: 220px !important;
                    height: 147px !important;

                    &.is-active {
                        border-color: $cc-blue;
                    }

                    &:not(.is-active) {
                        opacity: .7;

                        &:hover {
                            opacity: 1;
                            border-color: $cc-blue;
                        }
                    }

                    img {
                        display: flex !important;
                    }
                }                
            }
        }
    }
}