.special-scroll {    
    z-index: 10;
    position: absolute;
    bottom: u(55);
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq($from: $viewport--xs, $until: $viewport--md, $media-type: $mq-type, $mq_debug: null) {
        bottom: u(25);
    }

    @include mq($from: $viewport--xs, $until: 1666px, $media-type: $mq-type, $mq_debug: null) {
        bottom: u(30);
    }

    .text {
        font-size: u(13);
        color: $white;
        margin-bottom: u(12);
        transform: rotate(-90deg);
        padding-left: 20px;
    }

    .line {
        position: relative;
        background-color: rgba($color: $white, $alpha: .3);
        width: u(1);
        height: u(48);
        z-index: 1;

        &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        background-color: $cc-blue;
        width: u(1);
        height: 100%;
        z-index: 10;

        animation: line 2s ease;
        animation-iteration-count: infinite;
        }
    }
}

@keyframes line {
    0%{
    transform: scaleY(0);
    transform-origin: top center;
    }
    49% {
    transform: scaleY(1);
    transform-origin: top center;
    }
    50% {
    transform: scaleY(1);
    transform-origin: bottom center;
    }
    100% {
    transform: scaleY(0);
    transform-origin: bottom center;
}
}