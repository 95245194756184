/* Lang */
.Nav--lang {
    @include position(relative, $z: 1);
    margin-left: 15px;

    &:not(:hover) {
        .Nav-list {
            opacity: 0;
            visibility: hidden;
        }
    }

    // Toggle
    .Nav-toggle {
        cursor: default;
        align-items: center;
        display: flex;
        color: $cc-darkGrey;
        font-size: 20px;
        font-family: $primaryFont;
        font-weight: 400;
    }

    // List
    .Nav-list {

        @include transition(#{opacity, visibility});
        @include position(absolute, $top: 100%, $left: 0);
        color: $black;
        background-color: $white;
        flex-direction: column;
    }

    .Nav-item {
        height: auto;
        padding: u(10) 0;
    }

    // Link
    .Nav-link {
        color: $black;
        transition: color .2s ease, text-decoration .2s ease;
        margin-left: 0;


        &::after {
            display: none;
        }

        &:hover, &:active, &:focus {
            color: $cc-grey;

            &::after {
                display: none;
            }
        }

        &.is-active {
            color: $cc-grey;
            
            &::after {
                display: none;
            }
        }
    }
}
