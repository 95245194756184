.JobItemsPage {
    .p-header {
        .imager-container {
            height: inherit;
        }
    }
}

.information {
    &-body {
        max-width: $container;
        padding: 0 20px;        
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-column-gap: 20px;
        width: 100%;

        .apply {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            margin-top: 40px;

            .share {
                display: flex;
                align-items: center;
                justify-content: flex-end;                
                margin-top: 20px;

                > span {
                    margin-right: 20px;
                    border-bottom: 1px solid $cc-black;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                }

                > * {
                    margin-left: 15px;
                }

                svg {
                    height: 16px;
                }
            }

            .cta {
                background-color: unset;
                border-color: $cc-black;
                color: $cc-black;

                &:hover {
                    background-color: $cc-black;
                    color: $cc-white;
                }
            }
        }

        &--blocks {
            max-width: 960px;
            width: 100%;
            margin-top: 80px;
            margin-bottom: 80px;

            aside {
                p {
                    text-transform: uppercase;
                    color: $cc-blue;
                }
            }

            > .title {
                margin-bottom: 80px;
                padding-right: 20px;
                word-break: break-word;
                hyphens: auto;

                h2 {
                    width: 100%;
                    font-size: 54px;
                    line-height: 64px;
                }
            }

            > article {
                > h2 {
                    font-weight: bold;
                    font-size: 26px;
                    line-height: 30px;
                    margin-bottom: 10px;
                }

                > div {
                    * {
                        font-size: 20px;
                        line-height: 26px;
                        margin-bottom: 0;
                    }
                }

                ul {
                    margin-top: 20px;
                    padding: 0;

                    li {
                        list-style: square;
                        margin-left: 20px;
                    }
                }
            }

            > article + article {
                margin-top: 50px;
            }
        }
    }

    @include mq($until: $viewport--md) {
        &-body {
            display: flex;
            flex-direction: column-reverse;

            .apply {
                margin-top: 20px;

                .share {
                    margin-top: 10px;
                }
            }

            &--blocks {
                margin-top: 20px;

                .title {
                    margin-bottom: 40px;

                    h2 {
                        font-size: 44px;
                        line-height: 54px;
                    }
                }
            }
        }
    }
   
    @include mq($until: $viewport--sm) {
        &-body {
            &--blocks {
                margin-bottom: 40px;

                .title {
                    margin-bottom: 20px;

                    h2 {
                        font-size: 40px;
                        line-height: 50px;
                    }
                }

                > article {
                    h2 {
                        font-size: 22px;
                        line-height: 26px;
                    }
                }

                > article + article {
                    margin-top: 30px;
                }
            }
        }
    }
}