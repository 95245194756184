.content-builder {
    padding: 80px 0;    

    > article {
        display: flex;      
        align-items: center;   
        margin-left: auto;
        margin-right: auto; 

        img {
            height: 100%;
            object-fit: cover;
        }

        figure {
            picture {   
                position: absolute;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    
        > .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;            
            width: 500px;

            > * {
                max-width: 500px;
            }

            > div {
                margin: 20px 0;
            }
        }
        
        > .image {
            position: relative;
            height: 560px;
            display: flex;

            figure {                
                min-width: 50vw;
                height: 560px;
                background-size: cover;
                background-position: left top;
                position: absolute;
                right: 0;
                border-radius: 10px;
                overflow: hidden;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                picture {   
                    position: absolute;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                @include mq($from: 2800px) {
                    max-width: 640px;
                    min-width: 640px;
                }
            }
        }

        .buttons {
            display: flex;
            flex-wrap: wrap;
            margin: 0 !important;
            margin-top: -10px !important;
        
            > a {
                margin-top: 10px;
                margin-right: 10px;
            }
        }

        > * {
            &:nth-child(2) {
                margin-left: $alignment-medium;
            }
        }

        .information {
            position: relative;

            .background-text {
                position: absolute;
                z-index: 1;
                top: 50%;
                font-size: 150px;
                font-weight: bold;
                opacity: .2;
                line-height: 30px;
                color: $cc-blue;
                left: -180px;
                margin-top: -20px;

                @include mq($until: 1800px) {
                    left: 40px;
                }

                @include mq($until: 1500px) {                    
                    left: 20px;
                }
                
                @include mq($until: 1200px) {                    
                    display: flex;
                    font-size: 40px;
                    position: relative;
                    opacity: 1;
                    left: 0;
                    margin-bottom: 10px;
                    margin-top: 0;
                }
                
                @include mq($until: $viewport--sm) {   
                    font-size: 30px;                 
                }
            }
        }

        &.bigger-image, &.full-image {
            max-width: $container;
            padding: 80px 20px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: flex-end;

            > * {
                &:nth-child(2) {
                    max-width: 640px;
                    width: 100%;
                }
            }
        }

        &.normal {
            max-width: 1320px;
            padding: 80px 20px;
            width: 100%;

            .image {
                max-width: 480px;
                width: 100%;
            }
        }
        
        &.bigger-image {   
            min-height: 760px;

            > figure {                
                min-height: 760px;
                max-height: 760px;
            }
        }
        
        &.full-image {           
            .text {
                padding-left: 0px;
                margin-left: 320px;
            }

            &.image-right {
                flex-direction: row-reverse;
                justify-content: flex-start;

                .text {
                    margin-left: 0;
                    min-width: 500px;
                    max-width: 500px !important;
                }
                
                .image {
                    margin-left: 320px;
                    width: 100%;   
                    max-width: 650px;                 

                    figure {
                        left: 0;
                    }
                }

                @include mq($until: 1500px) {
                    justify-content: flex-end;

                    .text {
                        width: 500px;
                    }

                    .image {
                        max-width: 600px;
                        margin-left: 110px !important;
                    }
                }

                @include mq($until: 1250px) {
                    justify-content: space-between !important;

                    .text {    
                        width: 100%;                    
                                            
                    }

                    .image {
                        max-width: 530px;
                        margin-left: 80px !important;        
                    }
                }

                @include mq($until: 900px) {
                    flex-direction: column;

                    .text {
                        width: 100%;
                        min-width: unset;
                        max-width: unset;
                    }

                    .image {
                        max-width: unset;
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {            
            .text {
                padding-right: 0 !important;        
            }
        }
        
        &.only-image {
            margin-left: 0;
            margin-right: 0;
            padding: 80px 0;

            figure {
                width: 100%;
                max-width: unset;   
                max-height: unset;            
                height: 36vw;
                max-height: 1100px;
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    @include mq($from: 3000px) {
        > article {
            &.only-image {
                figure {
                    max-height: unset;
                }
            }
        }
    }

    @include mq($until: 1500px) {
        > article {
            justify-content: center !important;

            > * {
                &:nth-child(2) {
                    margin-left: 110px !important;
                }
            }

            &.full-image {
                .image {
                    max-width: 500px;
                    width: 100%;
                }
            }
        }
    }

    @include mq($until: 1250px) {
        > article {
            padding: 20px;

            > * {
                &:nth-child(2) {
                    max-width: 500px !important;
                    width: 100% !important;

                    &.text {
                        padding-right: 80px;
                    }
                }
            }
        }
    }

    @include mq($until: 900px) {
        > article {
            flex-direction: column;
            align-items: flex-start;

            &:not(.only-image) {
                padding: 20px !important;
            }

            &.only-image {
                padding: 20px 0 !important;

                figure {
                    height: auto;

                    picture {
                        position: relative;
                        margin-left: -16px;
                        
                        img {
                            position: relative;
                            top: unset;
                            left: unset;
                            height: auto;
                            object-fit: unset;
                            margin-top: -24px;
                        }
                    }
                }
            }

            .text {
                max-width: 500px;
                width: 100%;

                h3 {
                    margin-bottom: 0;
                }
            }

            > * {
                &:nth-child(2) {
                    margin-left: 0 !important;
                }
            }
            
            &.bigger-image {
                .image {
                    border-radius: 10px;
                    min-height: unset;
                    margin: 50px 0;
                    width: 100% !important;
                    max-width: unset !important;
                    max-height: 500px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            &.full-image {
                .image {
                    max-width: unset;
                    margin-bottom: 40px;

                    figure {
                        min-width: unset;
                        width: 100%;
                    }
                }
            }

            &.normal {
                .image {
                    margin-top: 40px;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    @include mq($until: $viewport--sm) {
        padding: 40px 0;

        > article {
            &:not(.only-image) {
                .image {
                    border-radius: 10px;
                    overflow: hidden;
                    height: 340px;
    
                    * {
                        width: 100% !important;
                        height: 100% !important;
                        background-size: cover;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

.Contentbuilder {
    &-detail {
        max-width: 980px;
        margin: 80px auto 100px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    
        > * {
            &:nth-child(n+1) {
                margin-top: 40px;
            }
        }

        &--topTitle {
            max-width: 700px;
            margin-bottom: 40px;

            aside {
                p {
                    text-transform: uppercase;
                    color: $cc-blue;
                }
            }
    
            h2 {
                font-size: 54px;
                line-height: 64px;
                font-weight: 500;
            }
        }

        &--titleText {
            display: flex;
            flex-direction: column;

            h2 {
                font-size: 26px;
                font-weight: bold;
                line-height: 30px;
                margin-bottom: 10px;
            }

            a {
                color: $cc-blue;
            }
        }

        &--titleBig {
            font-size: 54px;
            line-height: 64px;
            font-weight: 500;
        }

        &--image {    
            max-width: 640px;
            max-height: 440px;

            img {
                max-width: 640px;
                max-height: 440px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--video {
            max-width: 640px;
            height: 440px;

            .video-js.vjs-fluid {
                padding-top: 440px;
            }
        }

        @include mq($until: $viewport--sm) {
            margin-top: 40px;
            margin-bottom: 20px;
            
            > * {
                &:nth-child(n+1) {
                    margin-top: 20px;
                }
            }

            &--topTitle {
                margin-bottom: 10px;

                h2 {
                    font-size: 44px;
                    line-height: 54px;
                }
            }

            &--titleText {
                h2 {
                    font-size: 22px;
                    line-height: 26px;
                }
            }

            &--titleBig {
                font-size: 44px;
                line-height: 54px;
            }

            &--video {
                height: max-content;

                .video-js.vjs-fluid {
                    padding-top: 350px;
                }
            }
        }
    }
}