.Breadcrumbs {
    display: flex;
    max-width: $container;
    padding: 0 20px;
    margin: 0 auto;
    margin-bottom: 10px;

    > * {
        display: flex;
        align-items: center;
        color: $cc-grey;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        margin-right: 30px;
        white-space: nowrap;

        &.active {
            color: $cc-blue;
        }
    }
    
    > * + * {
        position: relative;        

        &:before {
            content: '';
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.166' height='12.918' viewBox='0 0 7.166 12.918'%3E%3Cpath id='Path_208' data-name='Path 208' d='M-10096.485,530.473l-6.105,6.106,6.105,6.106' transform='translate(-10096.132 543.038) rotate(180)' fill='none' stroke='%23E6E5E6' stroke-width='1'/%3E%3C/svg%3E");
            background-size: 100%;
            width: 7px;
            height: 13px;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            margin-left: -18px;
        }
    }
}
