/* Colors: Custom */

//Example: $cc-[NAME]: #......

// Dewever colors
$cc-white: white;
$cc-lightestGrey: #f0f0f0 !default;
$cc-lightGrey: #E6E5E6 !default;
$cc-grey: #B9B9B9 !default;
$cc-darkGrey: #888888 !default;
$cc-darkerGrey: #8B8B8B !default;
$cc-darkestGrey: #3B3B3B !default;
$cc-black:#333333 !default;
$cc-blackShade: #435572 !default;
$cc-blue: #7AA7EB !default;