.List {
    &-page-content {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        margin-top: 260px;       
        
        h1 {
            text-align: center;
            padding: 0 20px;            
        }
    }

    &-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 130px 0;

        &--pagination {
            display: grid;
            grid-template-columns: 50px 1fr 50px;
            width: 100%;
            margin-top: 130px;

            &-count {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                color: $cc-blue;
                font-weight: 500;
            }

            &-arrow {
                &-prev {
                    transform: rotate(180deg);
                }

                &-next {

                }
            }
        }        
    }

    &-item {
        &--fiftyfifty-container + &--fiftyfifty-container {
            margin-top: 60px;
        }

        &--fiftyfifty {
            display: flex;
            height: 440px;
            border-radius: 15px;
            border: 1px solid transparent;
            transition: border-color .2s ease;
            box-shadow: 0 3px 6px rgba(0,0,0, 0.16);

            &:hover {
                border: 1px solid $cc-blue;

                .List-item {
                    &--image {
                        .marker {
                            color: $cc-white;
                        }
                    }

                    &--extratag {
                        p {
                            color: $cc-blue;
                        }
                    }

                    &--description {
                        color: $cc-black;
                    }

                    &--readmore {
                        color: $cc-black;
                        border-color: $cc-blue;
                    }
                }
            }
        }
        
        &--image {
            width: 50%;
            position: relative;
            border-right: 1px solid $cc-grey;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            overflow: hidden;

            .marker {
                position: absolute;
                top: 0;
                left: 0;
                background-color: $cc-blue;
                color: $cc-white;
                padding: 10px 25px;
                border-bottom-right-radius: 15px;
                border-top-left-radius: 15px;
                font-weight: bold;
                font-size: 20px;
                line-height: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                z-index: 1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                position: absolute;
                left: 0;
            }
        }

        &--info {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 60px 30px;     
            
            .text {
                display: flex;
                flex-direction: column;       
                max-width: 350px;
                width: 100%;
            }
        }
        
        &--extratag {
            p {
                text-transform: uppercase;
                color: $cc-blue;
            }
        }
        
        &--title {
            font-weight: 600;
            font-size: 26px;
            line-height: 30px;
        }
        
        &--description {
            font-weight: 400;
            font-size: 20px;
            line-height: 26px;
            margin: 15px 0;
        }
        
        &--readmore {
            width: max-content;
            border-bottom:  1px solid $cc-black;
            color: $cc-grey;
            transition: color .2s ease, border-color .2s ease;
        }
    }

    @include mq($until: 1200px) {
        &-items {                       
            .List-item {
                &--fiftyfifty {
                    flex-direction: column;
                    border-radius: 15px;
                    height: auto;
                }

                &--image {
                    border-radius: 15px;
                    width: 100%;
                    height: 300px;
                }

                &--info {
                    margin-left: auto;
                    margin-right: auto;
                    width: 80%;

                    .text {
                        max-width: unset;
                    }
                }
            }        
        }
    }

    @include mq($until: $viewport--md) {
        &-page-content {
            margin-top: 180px;
        }

        &-items {       
            margin: 80px 0;                

            &--pagination {
                margin-top: 80px;

                * {
                    font-size: 26px;
                }
            }

            .List-item {              
                &--info {      
                    width: 100%;
                    padding: 40px;              
                }
            }        
        }
    }

    @include mq($until: $viewport--sm) {
        &-page-content {
            margin-top: 140px;
        }

        &-items {       
            margin: 40px 0;

            &--pagination {
                margin-top: 40px;

                * {
                    font-size: 20px;
                }
            }

            .List-item {              
                &--info {      
                    padding: 40px 20px; 
                    
                    h2 {
                        word-break: break-word;
                        hyphens: auto;
                    }
                }
            }        
        }
    }
}