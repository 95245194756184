.p-header {
    max-width: $container;
    padding: 0 20px;
    margin: 200px auto 0;
    width: 100%;

    figure {
        border-radius: 15px;
        overflow: hidden;
        height: 400px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .Breadcrumbs {
        padding: 0;
    }  

    @include mq($until: $viewport--sm) {
        margin-top: 130px;
    }    
}