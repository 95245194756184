.CookieConsent {
    padding: 15px 20px;
    background-color: $cc-blue;

    > div {
        max-width: 700px;
        margin-right: 20px;

        p, a {
            font-size: 16px;
            line-height: 18px;
        }

        a {
            margin-left: 0;
        }
    }

    @include mq($until: 450px) {
        flex-direction: column;
        align-items: flex-start;

        button {
            margin-left: 0;
        }
    }
}