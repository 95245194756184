
.FakeDropdownWrapper {
    user-select: none;
    position: relative;
    float: left;
    margin-right: u(20);

    @include mq($until: $viewport--md) {
        width: 100%;
    }

    @include mq($from: $viewport--xs, $until: $viewport--md, $media-type: $mq-type, $mq_debug: null) {
        margin-right: 0;
    } 
}

.FakeDropdownToggle {
    max-width: 350px;
    padding-right: 50px !important;

    &:after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='7' viewBox='0 0 13 7'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Cpath id='Path_15' data-name='Path 15' d='M6.475,0h0L0-6.225.806-7,6.478-1.547,12.194-7,13-6.225,6.475,0Z' transform='translate(0 7)' fill='%23535353'/%3E%3C/clipPath%3E%3CclipPath id='clip-path-2'%3E%3Cpath id='Path_14' data-name='Path 14' d='M0,0H13V-7H0Z' transform='translate(0 7)' fill='%23535353'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='arrow-down' transform='translate(13 7) rotate(180)'%3E%3Cg id='Group_10' data-name='Group 10' clip-path='url(%23clip-path)'%3E%3Cg id='Group_9' data-name='Group 9' clip-path='url(%23clip-path-2)'%3E%3Cpath id='Path_13' data-name='Path 13' d='M-5-12H18V5H-5Z' transform='translate(0 7)' fill='%23535353'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        width: 13px;
        height: 7px;
        position: absolute;
        right: 20px;
        background-repeat: no-repeat;
        transform: rotate(180deg);
        transition: transform .2 ease;
    }

    &.is-active {
        &:after {
            transform: rotate(0deg);
        }
    }
}

.Disable {
    pointer-events: none;

    .FakeDropdownToggle {
        border: 2px solid $cc-darkGrey;
        color: $cc-darkGrey;

        svg {
            path {
                fill: $cc-darkGrey;
            }
        }
    }
}

.FakeDropdownNav {
    a {
        color: $cc-black;

        &.is-active {
            color: $cc-darkGrey;
        }
    }
}

.FakeDropdown {
    margin-top: u(-25);
    position: absolute;
    z-index: 3;
    opacity: 0;
    // top: u(73);
    width: u(350);
    visibility: hidden;
    background-color: white;
    border: 1px solid $cc-grey;
    transform: translateY(0);
    transition: opacity .3s ease, visibility .3s ease, transform .3s ease;
    max-height: 250px;
    overflow-y: scroll;

    &.fixed-size {
        height: 85px;
        overflow: hidden !important;
    }

    @include mq($until: $viewport--md) {
        width: 100%;
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px $cc-darkGrey;
        border: solid 3px transparent;
    }

    &.is-active {
        opacity: 1;
        visibility: visible;
        transform: translateY(73px);
    }

    .FakeLabel {
        font-size: u(16);

        margin: 0 !important;
        background-color: white;
        transition: background-color .3s;
        padding: u(15) u(20);


        &:hover {
            background-color: $cc-grey;
        }
    }

    .Form-field--radio input[type="radio"]:checked ~ .Form-indicator::after {
        background-color: transparent;
    }

    .Form-field--radio {
        color: black;

        &:not(:first-of-type) {
            margin-top: u(halve($spacing-unit--xs));
        }

        // input[type="radio"] {

        //     &:checked ~ .Form-indicator {
        //         border-color: $cc-darkGrey;
        //         background-color: white;
        //     }

        //     // UI state
        //     &:focus ~ .Form-indicator {
        //         border-color: $cc-darkGrey;
        //     }
        // }

        &.is-active {
            .Form-indicator {
                background-color: $cc-grey !important;
                background-color: white;
            }
        }
        

        .Form-indicator {
            background-color: white !important;
            border: 1px solid $cc-darkGrey;
            border-radius: 0;

            &:focus {
                outline: none !important;
            }
        }

        input[type="radio"]:checked ~ .Form-indicator {
           
        }
    }
}

.FakeDropdownToggle {
    font-size: u(15);
    color: $cc-darkGrey;
    text-decoration: none;
    font-size: u(16);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    float: left;
    // display: flex;
    justify-content: space-between;
    // margin-right: u(20);

    background-color: white;
    transition: background-color .3s ease, border-radius .1s ease;
    min-width: u(350);
    padding: u(15) u(20);
    position: relative;

    @include mq($until: $viewport--lg + 150) {
        width: 100%;
        margin-right: 0;
        margin-bottom: u(20);
    }

    span {
        display: inline-block;
        margin-left: 5px;
    }

    svg {
        transition: transform .3s;
        margin-left: u($spacing-unit--xs);

        path {
            fill: $cc-darkGrey;
        }
    }

    &.is-active {
        opacity: 1;
        border-radius: 0;

        svg {
            transform: rotate(180deg);

            path {
                fill: white;
            }
        }
    }

    &:hover {
        cursor: pointer;
        color: $cc-darkGrey;
        opacity: 1;

        svg {

            path {
                fill: white;
            }
        }
    }
}
