.InfoPagesPage {
    .page-header {
        h1 {
            p {
                margin-bottom: 0;
            }
        }
    }

    .content-builder {
        > article {
            > .image {
                .caption {
                    max-width: 440px;
                    font-size: 16px;
                    text-align: right;
                    margin-top: 20px;
                    height: unset !important;
                }
            }

            &.bigger-image, &.only-image {
                > .image {
                    display: flex;
                    flex-direction: column;
    
                    .imager-container {
                        position: relative;
                        height: 100%;
                    }

                    .caption {
                        text-align: left;
                    }
                }
            }

            @include mq($until: 900px) {
                &.bigger-image {
                    > .image {
                        min-height: 900px;
                        max-height: unset !important;
                    }
                }
            }
            
            @include mq($until: $viewport--sm) {
                &.bigger-image {
                    > .text {
                        margin-bottom: 20px;
                    }
                    
                    > .image {
                        min-height: 620px;
                        max-height: unset !important;
                    }
                }
            }

            &.only-image {
                .caption {
                    width: 100%;
                    max-width: 1680px;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 0 20px;
                }
            }

            &.bigger-image {
                @include mq($until: $viewport--sm) {
                    min-height: unset;

                    > .image {
                        margin: 0;
                    }
                }
            }
            
            &.full-image {
                
                @include mq($until: 1800px) {
                    max-width: unset;
                }
                
                @include mq($from: 1800px) {
                    max-width: 1800px;
                }

                > .image {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    width: 100%;                    

                    @include mq($until: 1800px) {
                        min-width: 50vw;
                    }
    
                    figure {
                        width: 100%;
                        position: relative;
                        border-radius: 0px !important;
                        background-repeat: no-repeat;
                        background-position-y: bottom;
                        background-position-x: right;
    
                        @include mq($from: 1800px) {
                            min-width: 640px;
                            background-size: contain;
                        }
                        
                        @include mq($until: 1800px) {                        
                            background-size: contain;       
                        }
                    }
                }

                &.image-right {
                    > .image {
                        align-items: flex-start;

                        figure {
                            background-position-x: left !important;
                        }

                        .caption {
                            text-align: left;
                        }
                    }
                }
                
                @include mq($until: 1800px) {                        
                    &.image-right {
                        > .image {
                            margin-right: -20px;
                            margin-left: 40px;

                            figure {
                                background-position: right center;
                            }
                        }

                        > .text {
                            min-width: unset;
                            max-width: unset !important;
                            margin-left: 0 !important;
                            padding-right: 20px;
                        }
                    }
    
                    &:not(.image-right) {                
                        > .image {    
                            margin-left: -20px;

                            figure {
                                right: unset;
                                left: 0;
                            }
                        }                       
                    }
                }

                @include mq($until: 900px) {                
                    > .image {
                        min-width: unset;
                        width: 100%;
                        margin-left: 0 !important;
                        margin-right: 0;
                        margin-bottom: 0;
                        border-radius: 0 !important;
                        align-items: flex-start !important;

                        figure {
                            background-position: left bottom !important;
                        }

                        

                        .caption {
                            text-align: left !important;
                        }
                    }                
                }

                @include mq($until: $viewport--sm) {
                    > .image {
                        height: 200px;

                        &.with-caption {
                            height: 280px;
                        }
                    }
                }
            }
        }
    }
}