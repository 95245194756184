
.splide {
    &__slide {
        margin-top: 0 !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
        height: 180px;

        button {
            height: inherit;

            img {
                height: inherit;
            }
        }

        &:first-of-type {
            margin-left: 0px !important;
        }
        
        &:last-of-type {
            margin-right: 10px !important;
        }

        @include mq($until: $viewport--sm) {
            height: 120px;
        }
    }

    &__track {
        padding-right: 30px;
        margin-top: 10px;
        border-radius: 10px;
    }

    &__arrows {
        @include mq($until: $viewport--sm) {
            display: none !important;
        }
    }

    &__arrow {
        .sr-only {
            display: flex;
        }
    }
}

  .thumbnails-track {
    position: relative;
  }
  
  /**
    Controls (previous/next buttons)
  */
  .arrow-wrapper {
    position: absolute;
    top: 0;
    width: 25px;
    height: 100%;
    z-index: 1;
    
    display: flex;
    align-items: center;    
  }
  
    .previous-button-wrapper { left: 0; }
    .next-button-wrapper { right: 0; }
  
  /** Previous and next buttons */
  .button {
    background: none;
    border: 0;
    cursor: pointer;
    
    font-size: 30px;
    color: rgba(0,0,0,.7);
  
    padding: 0;
    margin: 0 1px;
    flex: 0 0 25px;
    height: 100%;
    
    transition: all .2s linear;
    
    svg {
        path {
            transition: fill .2s ease;
        }
    }
  
    &:hover {
        background-color: $cc-blue;
        border-color: $cc-blue;
        
        svg {
            path {
                stroke: $cc-white;
            }
        }    
    }
  }
  
  
    .button:focus {
      outline: none;
      
      color: rgba(255,255,255,1);
    }
  
    .button:disabled {
      opacity: .3;
    }
  
  /** Single thumbnail */
  .thumbnail {
    position: relative;
  }
  
    .thumbnail-button {
      display: block;
      padding: 0;
      width: 100%;
      
      cursor: pointer;
      border: 0;
      background: none;
      transition: opacity .1s linear;
    }
  
      .thumbnail-button:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: box-shadow .1s linear;
      }
  
      .thumbnail-button:focus {
        outline: 0;
      }
  
        .thumbnail-button:focus img,
        .thumbnail-button:hover img,
        .thumbnail-button[aria-current="true"] img {
          opacity: 1;
        }
  
      .thumbnail-button img {
        display: block;
        width: 100%;
        height: 100%;
        opacity: .5;
        transition: opacity .2s ease;
        object-fit: cover;
      }